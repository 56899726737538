import axios from 'axios'
import React, { useEffect, useState } from 'react'
import BlogCards from '../components/BlogCards'

const Blogs = () => {
    const [blogs, setBlogs] = useState([])
    //Get Blogs
    const getAllBlogs = async () => {
        try {
            const { data } = await axios.get('/api/v1/blog/all-blog')
            if (data?.success) {
                setBlogs(data?.blogs)
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getAllBlogs()
    }, []);
    return (
        <div>{blogs && blogs.map((blog) =>
            <BlogCards
                id={blog?._id}
                isUser={localStorage.getItem('userId') === blog.user._id}
                title={blog?.title}
                discription={blog?.discription}
                image={blog?.image}
                username={blog?.user?.username}
                time={blog.createdAt}
            />)}</div>

    )
}

export default Blogs