import axios from 'axios'
import React, { useEffect, useState } from 'react'
import BlogCards from '../components/BlogCards'

const UserBlogs = () => {
    const [blogs, setBlogs] = useState([])

    //Get User Blogs
    const getUserBlogs = async () => {
        try {
            const id = localStorage.getItem('userId')
            const { data } = await axios.get(`/api/v1/blog/user-blog/${id}`)
            if (data?.success) {
                setBlogs(data?.userBlog.blogs);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getUserBlogs();
    }, [])
    console.log(blogs);
    return (
        <div>{blogs && blogs.length > 0 ? (
            (blogs.map((blog) =>
                <BlogCards
                    id={blog._id}
                    isUser={true}
                    title={blog.title}
                    discription={blog.discription}
                    image={blog.image}
                    username={blog.user.username}
                    time={blog.createdAt}
                />))
        ) : (
            <h1>You Havent Created a Blog</h1>
        )
        }</div>
    )
}

export default UserBlogs