import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';

const Register = () => {
    const navigate = useNavigate();
    //State
    const [inputs, setInputs] = useState({
        name: "",
        email: "",
        password: "",
    });
    // Handle inpit change
    const handleChange = (e) => {
        setInputs(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    //Form Submit Handle
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { data } = await axios.post('/api/v1/user/register', {
                username: inputs.name,
                email: inputs.email,
                password: inputs.password,
            });
            if (data.success) {
                alert('User Register Successfully');
                navigate('/login');
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box
                    maxWidth={450}
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    margin='auto'
                    marginTop={5}
                    boxShadow='10px 10px 20px #ccc'
                    padding={3}
                    borderRadius={5}
                >
                    <Typography
                        variant='h4'
                        sx={{ textTransform: 'uppercase' }}
                        padding={3}
                        textAlign='center'
                    >Register</Typography>
                    <TextField
                        placeholder='name'
                        value={inputs.name}
                        name='name'
                        type='text'
                        onChange={handleChange}
                        margin='normal'
                        required />
                    <TextField
                        placeholder='email'
                        value={inputs.email}
                        name='email'
                        type='email'
                        onChange={handleChange}
                        margin='normal'
                        required />
                    <TextField
                        placeholder='password'
                        value={inputs.password}
                        name='password'
                        type='password'
                        onChange={handleChange}
                        margin='normal'
                        required />
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        sx={{ marginTop: 3, borderRadius: 3 }}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={() => navigate('/login')}
                        sx={{ marginTop: 3, borderRadius: 3 }}>
                        Already Register ? Please Login
                    </Button>
                </Box>
            </form>
        </>
    )
}



export default Register