import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const Edit = () => {
    const [blog, setBlogs] = useState({});
    const id = useParams().id;
    const navigate = useNavigate();
    const userID = localStorage.getItem('userId')
    //state magement
    const [inputs, setInputs] = useState({})
    const editBlog = async () => {
        try {
            const { data } = await axios.get(`/api/v1/blog/get-blog/${id}`)
            if (data?.success) {
                setBlogs(data?.blog);
                setInputs({
                    title: data?.blog.title,
                    discription: data?.blog.discription,
                    image: data?.blog.image,
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        editBlog();
        // eslint-disable-next-line
    }, [id]);
    console.log(blog);
    //Input Change handleChange
    const handleChange = (e) => {
        setInputs(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        })
        )
    };
    //Form
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.put(`/api/v1/blog/update-blog/${id}`, {
                title: inputs.title,
                discription: inputs.discription,
                image: inputs.image,
                user: userID,
            })
            if (data?.success) {
                alert('Blog Updated Successfully')
                navigate('/my-blogs')
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box width={'50%'}
                    border={1}
                    borderRadius={5}
                    padding={1}
                    margin='auto'
                    boxShadow={'10px 10px 20px #ccc'}
                    display='flex'
                    flexDirection='column'
                    marginTop={3}>
                    <Typography variant='h3'
                        textAlign='center'
                        fontWeight='bold'
                        padding={1}
                        color='gray'>
                        Edit Post
                    </Typography>
                    <InputLabel sx={{
                        mb: 1,
                        mt: 2,
                        fontSize: '25px',
                        fontWeight: 'bold',
                    }}> Title
                    </InputLabel>
                    <TextField
                        value={inputs.title}
                        name='title'
                        onChange={handleChange}
                        marginTop='normal'
                        variant='outlined'
                        placeholder='title'
                        required
                    ></TextField>
                    <InputLabel sx={{
                        mb: 1,
                        mt: 2,
                        fontSize: '25px',
                        fontWeight: 'bold',
                    }}> Discription
                    </InputLabel>
                    <TextField
                        value={inputs.discription}
                        name='discription'
                        onChange={handleChange}
                        marginTop='normal'
                        variant='outlined'
                        placeholder='discription'
                        required
                    ></TextField>
                    <InputLabel sx={{
                        mb: 1,
                        mt: 2,
                        fontSize: '25px',
                        fontWeight: 'bold',
                    }}> Image URL
                    </InputLabel>
                    <TextField
                        value={inputs.image}
                        name='image'
                        onChange={handleChange}
                        marginTop='normal'
                        variant='outlined'
                        placeholder='image'
                        required
                    ></TextField>
                    <Button type='submit' sx={{ mt: 2 }} color='warning' variant='contained'>Update</Button>
                </Box>
            </form>
        </>
    )
}

export default Edit