import React, { useState } from 'react'
import { Box, AppBar, Toolbar, Button, Typography, Tab, Tabs } from "@mui/material"
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../redux/store'

const Header = () => {
    //Global State
    const isLogin = useSelector(state => state.isLogin)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log(isLogin);
    //State
    const [value, setvalue] = useState()
    //handle Logout
    const handleLogout = () => {
        try {
            dispatch(authActions.logout())
            alert("Logout Successfully")
            navigate('/')
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <AppBar position='sticky'>
                <Toolbar>
                    <Typography variant='h4'>
                        <Button sx={{ margin: 1, color: 'white', fontSize: '1.3rem' }} LinkComponent={Link} to='/'>My Blog App</Button>
                    </Typography>
                    {isLogin && (
                        <Box display={'flex'} marginLeft='auto' marginRight='auto'>
                            <Tabs textColor='inherit' value={value} onChange={(e, val) => setvalue(val)}>
                                <Tab label='Blogs' LinkComponent={Link} to='/blogs' />
                                <Tab label='My Blogs' LinkComponent={Link} to='/my-blogs' />
                                <Tab label='Create Blog' LinkComponent={Link} to='/create-blog' />
                            </Tabs>

                        </Box>)}
                    <Box display={'flex'} marginLeft='auto'>
                        {!isLogin && (<>
                            <Button sx={{ margin: 1, color: 'white' }} LinkComponent={Link} to='/login'>Login</Button>
                            <Button sx={{ margin: 1, color: 'white' }} LinkComponent={Link} to='/register'>Register</Button>
                        </>
                        )}
                        {isLogin && (<Button onClick={handleLogout} Button sx={{ margin: 1, color: 'white' }}>Logout</Button>)}
                    </Box>
                </Toolbar>
            </AppBar >
        </>
    )
}

export default Header